@tailwind base;
@tailwind components;
@tailwind utilities;

.text-gradient-right-to-left {
  @apply text-transparent;

  /* Specify the gradient colors */
  background-image: linear-gradient(to left, #6286ff, #6286ff, #6c6c70, #6c6c70, #4682B4, #4682B4, #6286ff, #6286ff);
  /* White to emerald-600 */
  -webkit-background-clip: text;
  background-clip: text;
}

.button-gradient-border {

  padding: 7px 20px;

  border: 3px solid transparent;
  /* Transparent border for proper effect */
  background-image: linear-gradient(white, rgb(231, 231, 245)),
    /* Fallback background */
    linear-gradient(to left, #6286ff, #a757da, #6286ff, #6286ff, #6c6c70, #6c6c70, #6286ff, #6286ff, #a757da);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  /* transition: background 0.4s ease; */
}

.right-to-left {
  @apply text-transparent;

  /* Specify the gradient colors */
  background-image: linear-gradient(to left, red, blue, gray);
  /* White to emerald-600 */
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(20%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


@keyframes slideFromTop {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideFrombottom {
  0% {
    transform: translateY(20%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-left-to-right {
  animation: slideFromLeft 0.5s ease-out forwards;
}

.animate-right-to-left {
  animation: slideFromRight 0.5s ease-out forwards;
}

.animate-top-to-bottom {
  animation: slideFromTop 0.5s ease-out forwards;
}

.animate-bottom-to-top50 {
  animation: slideFrombottom 0.4s ease-out forwards;
}

.animate-bottom-to-top {
  animation: animate-bottom-to-top 0.7s ease-in-out;
  /* Animation duration and easing */
}

@keyframes animate-bottom-to-top {
  from {
    transform: translateY(6%);
    /* Start off-screen from the bottom */
    opacity: 0;
    /* Start invisible */
  }

  to {
    transform: translateY(0);
    /* End in original position */
    opacity: 1;
    /* End fully visible */
  }
}